<script lang="ts">
  
</script>

<div>
  <!-- How many columns? -->
  <!-- Pre release or not? -->

  <!-- For each game:
    Collection
    Tournament

    At the end:
    * friendship
    * NPC
    
    -->
</div>
